import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Components
import Slider from "react-input-slider"
import AnimatedNumber from "animated-number-react"

// GraphQL
import client from "../../service/graphql"
import querys from "../../service/querys"

import { calculateInstallmentValue } from "./calculate"

import { theme } from "../../styles/theme"

import {
  Card,
  CardHeader,
  CardBody,
  Minus,
  Plus,
  Amount,
  Month,
  MonthValue,
  HighLight,
  HR,
  Disclaimer,
  Total,
  Value,
  SliderValues,
  Button,
  TitleHeader,
  ContainerSplit,
  Split,
  TitleSplit
} from "./styles"

const Simulator = ({ trackingUtm }) => {
  const [value, setValue] = useState(2500)
  const [fees, setFees] = useState(null)
  const [months, setMonths] = useState(6)

  const formatValue = value => String(Number(value).toFixed(2)).replace('.', ',')

  const handleMinus = () => {
    if (value > 500) {
      setValue(parseFloat(value - 100))
    }
  }

  const handlePlus = () => {
    if (value < 5000) {
      setValue(parseFloat(value + 100))
    }
  }

  useEffect(() => {
    async function loadData() {
      const { fees } = await client.request(querys.GET_FEES)
      const obj = {}
      fees.forEach(fee => {
        obj[fee.type] = fee.value
      })

      setFees(obj)
    }
    loadData()
  }, [])

  return (
    <Card>
      <CardHeader>
        <Minus onClick={handleMinus}>-</Minus>
        <Amount>
          R$
          {value.toLocaleString("pt-BR")}
        </Amount>
        <Plus onClick={handlePlus}>+</Plus>
      </CardHeader>
      <Slider
        styles={{
          track: {
            marginTop: "20px",
            width: "100%",
            backgroundColor: theme.colors.greenLighter,
            boxShadow: "none",
          },
          active: {
            backgroundColor: theme.colors.greenDark,
          },
          thumb: {
            cursor: "ew-resize",
            width: 24,
            height: 24,
            backgroundColor: theme.colors.green,
          },
        }}
        axis="x"
        xstep={100}
        xmin={500}
        xmax={5000}
        x={value}
        onChange={({ x }) => setValue(parseFloat(x.toFixed(2)))}
      />
      <SliderValues>
        <div>R$ 500</div>
        <div>R$ 5.000</div>
      </SliderValues>

      <TitleHeader>Selecione a quantidade de parcelas:</TitleHeader>

      <ContainerSplit>
        <Split
          style={{ marginRight: 5 }}
          selected={months === 6}
          onClick={() => setMonths(6)}
        >
          <TitleSplit>6x</TitleSplit>
        </Split>
        <Split
          style={{ marginLeft: 5 }}
          selected={months === 12}
          onClick={() => setMonths(12)}
        >
          <TitleSplit>12x</TitleSplit>
        </Split>
      </ContainerSplit>

      <CardBody>
        <Month>
          Com a Veriza você paga <br /> <HighLight>{months} parcelas*</HighLight> de
        </Month>
        <MonthValue>
          R$
          <AnimatedNumber
            value={(calculateInstallmentValue(fees, value, months))}
            formatValue={formatValue}
            duration={500}
          />
        </MonthValue>
        <HR />
        <Total>
          <p>Valor total aproximado:</p>
          <Value>
            R$
            <AnimatedNumber
              value={(calculateInstallmentValue(fees, value, months) * months)}
              formatValue={formatValue}
              duration={1000}
            />
          </Value>
          <p>Taxa de {(fees?.INTEREST * 100)?.toFixed(1)}% ao mês</p>
        </Total>
        <HR />
        <Disclaimer>
          O valor acima refere-se à quantia que <span>cada participante</span>{" "}
          do grupo receberá.
        </Disclaimer>
      </CardBody>

      <Link
        to="/cpfSerasa"
        state={{ value, months, trackingUtm }}
        style={{ textDecoration: "none" }}>

        <Button>
          <h3>Iniciar a solicitação</h3>
        </Button>
      </Link>
    </Card>
  )
}

export default Simulator
