import styled from "styled-components"
import { Link } from "gatsby"

export const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.greenLighter};
`

export const HeaderModal = styled.div`
  display: flex;
  margin-top: 35px;
  margin-right: 10px;
  justify-content: flex-end;
`

export const CloseIcon = styled.img`
  src: url(${props => props.src});
  width: 20px;
  height: 20px;
`
export const MenuContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-top: 30px;
  height: 210px;
  margin-bottom: 250px;
`

export const ItemMenu = styled(Link)`
  text-decoration: none;
  margin-botton: 100px;
  p {
    font-size: 24px;
    font-weight: bold;
    line-height: 46px
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SocialIcons = styled.img`
  src: url(${props => props.src});
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: 32px;
`