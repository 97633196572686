import styled, { css } from "styled-components"

export const Card = styled.div`
  padding: 28px 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(19, 84, 52, 0.2);
  border: solid 1px rgba(44, 45, 44, 0.1);
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SliderValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: ${props => props.theme.colors.greenDarker};
`

export const Minus = styled.p`
  display: block;
  font-size: 28px;
  font-weight: 500;
  opacity: 0.5;
  color: ${props => props.theme.colors.greenDarker};
  cursor: pointer;
`

export const Plus = styled.p`
  display: block;
  font-size: 28px;
  font-weight: 500;
  opacity: 0.5;
  color: ${props => props.theme.colors.greenDarker};
  cursor: pointer;
`

export const Amount = styled.p`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.8px;
  text-align: center;
  color: ${props => props.theme.colors.greenDarker};
`

export const CardBody = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Month = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.charcoal};
`

export const HighLight = styled.span`
  font-weight: 800;
`

export const MonthValue = styled.div`
  margin-top: 8px;
  line-height: 38px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.8px;
  text-align: center;
  color: ${props => props.theme.colors.charcoal};
`

export const HR = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
  width: 90%;
`

export const Disclaimer = styled.div`
  opacity: 0.7;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.colors.charcoal};
  margin: 0px 30px 0px 30px;

  span {
    font-weight: bold;
  }
`

export const Total = styled.div`
  text-align: center;

  p {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.charcoal};
  }
`

export const Value = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.9px;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 28px;
  color: ${props => props.theme.colors.charcoal};
`

export const Button = styled.div`
  display: flex;
  border-radius: 10px;
  max-width: 287px;
  height: 48px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  :hover {
    background-color: ${props => props.theme.colors.mango};
    opacity: 0.5;
    cursor: pointer;
  }

  h3 {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }
`
export const TitleHeader = styled.p`
  margin: 15px 0px 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #2C2D2C;
`;

export const TitleSplit = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #2C2D2C;
`;

export const ContainerSplit = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

export const Split = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid #89A999;
  border-radius: 10px;
  padding: 15px;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #52b232;
      border: 1px solid #52b232;

      p {
        color: white;
      }
    `}
`