import React from "react"

import Close from "../../images/close.png"
import TwitterIcon from "../../images/twitter-icon.png"
import FacebookIcon from "../../images/facebook-icon.png"
import LinkedinIcon from "../../images/linkedin-icon.png"
import InstagramIcon from "../../images/instagram-icon.png"

import {
  HeaderModal,
  CloseIcon,
  MenuContainer,
  ItemMenu,
  ModalFooter,
  SocialIcons,
} from "./styles"

export default function HamburgerMenuMobile({closeCallBack}) {
  return (
    <>
      <HeaderModal >
        <CloseIcon src={Close} onClick={() => closeCallBack(false)}/>
      </HeaderModal>
      <MenuContainer>
        <ItemMenu onClick={() => closeCallBack(false)}><p>Início</p></ItemMenu>
        <ItemMenu to="/faq"><p>Perguntas frequentes</p></ItemMenu>
        <ItemMenu to="http://blog.veriza.com.br/"><p>Blog Veriza</p></ItemMenu>
      </MenuContainer>
      <ModalFooter>
        <a href="https://www.linkedin.com">
          <SocialIcons src={LinkedinIcon} width={"25px"} height={"25px"}/>
        </a>
        <a href="https://www.instagram.com/verizapravoce/">
          <SocialIcons src={InstagramIcon} width={"24px"} height={"24px"}/>
        </a>
        <a href="https://www.facebook.com/verizapravoce/?ref=br_rs">
          <SocialIcons src={FacebookIcon} width={"24px"} height={"24px"}/>
        </a>
      </ModalFooter>
    </>
  )
}
