import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  width: 103%;
  background-color: #D34407;
  text-align: center;
  align-self: center;
  padding: 0.6%;
  color: white;
  margin-bottom: 2rem;
`
export const Image = styled.img`
  margin-right: 0.5rem;
`

export const Text = styled.p`
  margin-right: 3rem;
  font-size: 0.8rem;
  ${media.lessThan("medium")`
    margin-right: 5rem;
  `}
`