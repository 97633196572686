import { Link } from "gatsby";
import React from "react"
import alertIcon from "../../images/ico16_atencao.png";

import {
  Container,
  Image,
  Text
} from "./styles"

export default function Alert() {

  return (
    <Container>
      <Text>
      <Image src={alertIcon} />
        Atenção, a Veriza não solicita nenhum pagamento adiantado para a liberação do seu crédito, qualquer dúvida fale com a gente
      </Text>
    </Container>
  )
}
